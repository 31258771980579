.listePaiement{
    width: 100%;
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    .liste_wrapper{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .liste_wrapper-left{
            .liste_h2{
                color:  rgba(4, 40, 148, 0.952);
                font-weight: 800;
                text-transform: uppercase;
                font-size: 1.2rem;
                letter-spacing: 1px;
            }
        }
    }
}