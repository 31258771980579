@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Source+Sans+3:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,500;1,500&family=Teko:wght@300..700&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
}
a{
    color: black;
    text-decoration: none;
}
li{
    list-style: none;
}
.app_wrapper{
    min-height: 100vh;
}
.appContainer{
    position: relative;
    display: flex;
    width: 100%;
}
.appOutlet{
    flex: 8;
    width: 100%;
    min-height: 100vh;
    position: relative;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: #fafafa;
}
.outlet-wrapper{
    position: relative;
    overflow: hidden;
    padding: 30px;
    background: #f7f8f80a;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    border-bottom: 1px solid #cfcfcf;
    box-sizing: border-box;
}
.pointer-cursor {
    cursor: pointer;
  }
  
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
.loader-container-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loading-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }

  .login_password_wrapper {
    position: relative;
}

.login_password_eye {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.btn-submit{
    padding: 15px 20px;
    border: none;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    border-radius: 5px;
    cursor: pointer;
}

/* clientForm.css */

/* Styles pour le modal */
.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  background-color: #1890ff;
  color: #fff;
  border-radius: 8px 8px 0 0;
  padding: 16px;
  border-bottom: none;
}

.ant-modal-title {
  font-size: 20px;
  font-weight: bold;
}

.ant-modal-body {
  padding: 24px;
}

.ant-modal-footer {
  border-top: none;
  padding: 16px;
  text-align: right;
}

/* Styles pour le contenu du modal */
.ant-modal-body ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.ant-modal-body li {
  margin-bottom: 8px;
}

/* Styles pour les boutons du footer du modal */
.ant-modal-footer .ant-btn-primary {
  background-color: #1890ff;
  border-color: #1890ff;
  margin-left: 8px;
}

.ant-modal-footer .ant-btn-primary:hover,
.ant-modal-footer .ant-btn-primary:focus {
  background-color: #40a9ff;
  border-color: #40a9ff;
}


/* Loader */
.loader-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.hidden-column {
  display: none;
}


@media print {
  .no-print {
    display: none;
  }
}


  @media screen and (max-width: 568px){
    .outlet-wrapper{
        padding: 15px;
    }
  }

  @media screen and (max-width: 375px){
    .outlet-wrapper{
        padding: 20px;
    }
  }