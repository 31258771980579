@import '../../style.scss';

.home{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .home-rapport{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff;
        border-radius: 5px;
        border: 1px solid #c2c2c2bb;
        box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
        padding: 20px;
        margin-bottom: 20px;
        .home-left{
            color:  rgba(4, 40, 148, 0.952);
            border: 1px solid #c2c2c2bb;
            box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
            padding: 10px;
            border-radius: 10%;
            .home-span{
                font-size: .5rem;
                padding-left: 5px;
                letter-spacing: 2px;
                font-weight: 900;
            }
        }
    }
    .home-wrapper{
        width: 100%;
    }
    .home-rows{
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 20px;
        margin-top: 20px;
        @include mobileXR{
            display: grid;
            grid-template-columns: repeat(1,1fr);
        }
    }
}