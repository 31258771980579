.chartFalcon{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #c2c2c2bb;
    box-shadow: 0px 0px 15px -10px rgba(0,0,0,0.75);
    padding: 20px;
    .chartFalcon-title{
        border-bottom: 1px solid rgb(223, 223, 223);
        .chartFalcon-h2{
            font-size: 1rem;
            color: rgba(4, 40, 148, 0.952);
        }
    }
    &-title {
        margin-bottom: 10px;
      }
    
      &-h2 {
        font-size: 24px;
        color: rgba(4, 40, 148, 0.952);
      }
    
      &-chart {
        margin-top: 10px;
      }

}